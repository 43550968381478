<template>
  <div :class = "[{'header-border': show_switch_id_btn}]">
    <div id = "page-header" :class = "[{'center-logo':!show_accession_id}]">
      <img :id="customized_logo_src?'customized_logo':'vibrant-logo'" alt="Vibrant Logo" :src = "customized_logo_src?customized_logo_src:default_vibrant_logo" />
      <div v-if = "show_accession_id" id = "sample-id-info">
        Accession ID: {{ this.accession_id == 'undefined' ? '' : this.accession_id}}
      </div>
    </div>
    <div v-if = "show_switch_id_btn" class = "switch-accession-id-btn-bar">
       <div class = "switch-accession-id-btn" @click="handleClickSwitchId">
            Search another ID
       </div> 
    </div>
  </div>
</template>

<script>
import { usePaymentStore } from "@/stores/PaymentStore.ts";
import { useFedexPickupStore } from "@/stores/FedexPickupStore.ts";
import { mapState} from "pinia";
import default_vibrant_logo from '@/assets/vibrantLogo.svg';
export default{
    props:{
        accession_id:String,
    },
    setup(){
      const fedexPickupStore = useFedexPickupStore();
      return{
        fedexPickupStore,
      }
    },
    data(){
      return{
        default_vibrant_logo,
      }
    },
    computed:{
      ...mapState(usePaymentStore,[
        'customized_pns_logo',
        'provider_has_revenue',
      ]),
      currentPath() {
        console.log('currentPath',this.$route.path);
        return this.$route.path;
      },
      customized_logo_src(){
        return this.currentPath && this.currentPath.includes('/payment') && this.customized_pns_logo && this.provider_has_revenue
        ? 
        this.customized_pns_logo
        :
        "";
      },
      show_accession_id(){
        return this.currentPath != '/fedexPickup/search';
      },
      show_switch_id_btn(){
        return this.currentPath == '/fedexPickup/schedules';
      }
    },
    methods:{
      handleClickSwitchId(){
        if(this.currentPath == '/fedexPickup/schedules'){
          //clear accession_id in fedexPickupStore
          this.fedexPickupStore.accession_id = null;
          this.$router.push('/fedexPickup/search');
        }
      }
    }
}
</script>

<style scoped>
#page-header{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top:26px;
  padding-left: 94px;
  padding-right: 94px;
  width: 100%;
  background-color: #fff;
  column-gap: 10px;
  /* min-width: 1024px; */
  /* position:sticky;
  top:0;
  z-index: 100; */
}

#vibrant-logo{
  height:36px;
  /* width: 185px; */
}
#customized_logo{
  height:36px;
}
#sample-id-info{
  color: #2E557B;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.515px;
}
.switch-accession-id-btn-bar{
  /* margin-top:6px;
  padding: 0px 64px; */
  padding:6px 94px 0px 94px;
  display:flex;
  justify-content:flex-end;
  background-color: #fff;
}
.switch-accession-id-btn{
    color: #20A8C3;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
    text-decoration: underline;
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
  #page-header{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 24px;
    padding-left: 28px;
    padding-right: 28px;
    width: 100%;
  }
  .center-logo{
    justify-content: center !important;
  }
  .center-logo #vibrant-logo{
    height:24px;
  }
  .center-logo #customized_logo{
    height:24px;
  }
  #vibrant-logo{
    height:16px;
    /* width: 110px; */
  }
  #customized_logo{
    height:16px;
  }
  #sample-id-info{
    color: #2E557B;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
  .switch-accession-id-btn-bar{
    /* margin-top:4px; */
    /* padding:0px 28px 12px 28px; */
    padding:4px 28px 12px 28px;
  }
  .switch-accession-id-btn{
    font-size: 12px;
    line-height: 16px; 
  }
  .header-border{
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  }
}
</style>